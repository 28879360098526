import { ref } from "@vue/reactivity";
import { reactive } from "vue";
import {
    getQuestionnairesService,
    getQuestionnaireService,
} from "../../services/questionnaires/questionnairesServices";
export default () => {
    const loading = ref(false);
    const data = ref([]);
    const questionnaire = ref([]);
    const form = reactive({
        questionnaire_id: "",
        answers: {},
    });
    const getQuestionnaires = async () => {
        NProgress.start();
        loading.value = true;
        const response = await getQuestionnairesService();

        if (response.status === 200) {
            data.value = response.data;
        } else {
            Swal.fire("Error!", response.message, "error");
        }
        loading.value = false;
        NProgress.done();
    };
    const getQuestionnaire = async (id) => {
        NProgress.start();
        loading.value = true;
        const response = await getQuestionnaireService(id);

        if (response.status === 200) {
            questionnaire.value = response.data;
            Object.assign(form, {
                questionnaire_id: questionnaire.value.id,
            });
            const duplicateData = questionnaire.value.preguntas;
            for (const property in duplicateData) {
                form.answers[property] = [];

                duplicateData[property].forEach((q, index) => {
                    form.answers[property].push({
                        text: "",
                        question_id: q.id,
                        option_ids: [],
                    });
                });
            }
        } else {
            Swal.fire("Error!", response.message, "error");
        }
        loading.value = false;
        NProgress.done();
    };
    return {
        loading,
        getQuestionnaires,
        getQuestionnaire,
        data,
        questionnaire,
        form,
    };
};
