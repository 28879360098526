import peticionHttp from "../peticionHttp";
const service = "api";

export const getQuestionnairesService = async (id) => {
    const response = await peticionHttp.get(`${service}/questionnaires`, id);
    return response;
};
export const getQuestionnaireService = async (id) => {
    const response = await peticionHttp.get(`${service}/questionnaires/${id}`);
    return response;
};
export const addQuestionnairesService = async (form, id) => {
    const response = await peticionHttp.post(`${service}/replies`, form);
    return response;
};
