<template>
    <div class="spinner" v-show="loading" :style="{width: `${width}px`, height: `${height}px`}"></div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        },
    }
}
</script>

<style scoped>
.spinner {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

