<template>
  <div
    class="modal fade"
    :id="`questionnaire-${idQuestionnaire}`"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ questionnaire.name }}
          </h5>
          <button type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            action="/api/replies"
            method="POST"
            accept-charset="UTF-8"
            enctype="multipart/form-data"
            @submit.prevent="submitForm(questionnaire.id)"
          >
            <div class="row">
              <div
                class="col-md-12"
                v-for="(questions, key, index) in questionnaire.preguntas"
                :key="index"
              >
                <h4>{{ key }}</h4>
                <template
                  v-for="(question, index2) in questions"
                  :key="question.id"
                >
                  <div class="form-group">
                    <label class="form-label">{{ `${question.text} ` }}</label>
                    <input
                      v-if="question.question_type_id == 1"
                      type="text"
                      class="form-control"
                      :name="`answer[${key}][${index2}][text]`"
                      v-model="form.answers[key][index2].text"
                    />
                    <template v-else-if="question.question_type_id == 2">
                      <select
                        :name="`answer[${key}][${index2}][text]`"
                        class="form-control"
                        v-model="form.answers[key][index2].text"
                      >
                        <option disabled selected>Seleccione una opción</option>
                        <option
                          :value="option.id"
                          v-for="option in question.options"
                          :key="option.text"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                    </template>
                    <textarea
                      :name="`answer[${key}][${index2}][text]`"
                      v-model="form.answers[key][index2].text"
                      v-else-if="question.question_type_id == 4"
                      id=""
                      cols="10"
                      rows="5"
                      class="form-control"
                    ></textarea>
                    <div class="" v-else-if="question.question_type_id == 3">
                      {{ addOption(question.options, key, index2) }}
                      <div
                        class="form-check"
                        v-for="option in question.options"
                        :key="option.id"
                      >
                        <template v-if="option.text.toLowerCase() !== 'otros'">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="options_ids[]"
                            :id="`checkbox_${option.id}`"
                            :value="option.id"
                            v-model="form.answers[key][index2].option_ids"
                          />
                          <label
                            class="form-check-label"
                            :for="`checkbox_${option.id}`"
                            >{{ option.text }}</label
                          >
                        </template>
                        <template v-else>
                          <label class="form-label">{{
                            `${option.text} `
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            :name="`answer[${key}][${index2}][text]`"
                            v-model="form.answers[key][index2].text"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="col-md-12">
                <div class="form-footer">
                  <button type="submit" class="btn btn-primary btn-block">
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger light"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CModal from "@/components/modals/CModal.vue";
import ErrorMsg from "@/components/modals/ErrorMsg.vue";
import useGetQuestionnaires from "@/composables/questionnaires/useGetQuestionnaires.js";
import { addQuestionnairesService } from "@/services/questionnaires/questionnairesServices.js";
import { ref } from "vue-demi";
import { onUpdated } from "vue-demi";
import { watch } from "vue-demi";

export default {
  components: {
    CModal,
    ErrorMsg,
  },
  props: {
    idQuestionnaire: Number,
  },
  setup(props) {
    const count = ref(1);
    const { loading, getQuestionnaire, questionnaire, form } =
      useGetQuestionnaires();
    onUpdated(() => {
      count.value = props.idQuestionnaire;
    });

    getQuestionnaire(count.value);
    watch(count, (value) => {
      getQuestionnaire(count.value);
    });
    const count2 = ref(0);
    const addOption = (options, key, index) => {
      if (count2.value <= options.length) {
        count2.value = count2.value + 1;
        form.answers[key][index].option_ids.push("");
        form.answers[key][index].option_ids.shift();
        return;
      }
    };
    const submitForm = async (id) => {
      let formularioContestado = { questionnaire_id: "", answers: [] };
      for (const property in form.answers) {
        form.answers[property].forEach((question) => {
          if (Number.isInteger(question.text)) {
            question.option_ids.push(question.text);
            question.text = "";
            formularioContestado.answers.push(question);
          } else {
            formularioContestado.answers.push(question);
          }
        });
      }
      Object.assign(formularioContestado, {
        questionnaire_id: id,
      });
      const response = await addQuestionnairesService(
        formularioContestado,
        questionnaire.id
      );
      if (response.status === 200) {
        Swal.fire("Hecho", response.message, "success").then(() => {
          $("#modalAdd").modal("toggle");
        });
      } else {
        Swal.fire("Error!", response.message, "error").then(() => {
          $("#modalAdd").modal("toggle");
        });
      }
    };
    return {
      questionnaire,
      form,
      submitForm,
      addOption,
    };
  },
};
</script>
