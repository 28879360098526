<template>
  <bread-layout :items="breadcrumbLinks"></bread-layout>
  <div class="row">
    <AlertsPayment :nextPayments="getAuthUser?.client?.next_payment" />
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Cuestionarios</h3>
        </div>
        <div class="card-body">
          <div class="basic-list-group">
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(questionaire, index) in questionaires"
                :key="index"
              >
                <div class="d-flex justify-content-between">
                  <h4>{{ questionaire.name }}</h4>
                  <button
                    type="button"
                    class="btn btn-primary mb-2"
                    data-toggle="modal"
                    @click="getQuestionnaireId(questionaire.id)"
                    :data-target="`#questionnaire-${questionaire.id}`"
                  >
                    Mostrar Preguntas
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modalAddEdit :idQuestionnaire="questionnaireId" />
</template>

<script>
import AlertsPayment from "@/views/profile/AlertsPayment.vue";
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue-demi";
import BreadLayout from "../../layouts/BreadLayout.vue";
import modalAddEdit from "./ReplyAddEdit.vue";
import useGetQuestionnaires from "@/composables/questionnaires/useGetQuestionnaires.js";
import { onBeforeMount } from "vue-demi";
export default {
  components: { BreadLayout, modalAddEdit, AlertsPayment },
  setup() {
    const breadcrumbLinks = ref([
      {
        text: "Cuestionario",
        path: "/cuestionarios",
      },
    ]);
    const { getQuestionnaires, data: questionaires } = useGetQuestionnaires();
    onBeforeMount(() => {
      getQuestionnaires();
    });
    onMounted(() => {
      window.scrollTo( 0, 0 );
    });
    const questionnaireId = ref(1);

    const getQuestionnaireId = (id) => {
      questionnaireId.value = id;
    };
    const store = useStore();

    return {
      getAuthUser: computed(() => store.state.User.user),
      breadcrumbLinks,
      questionaires,
      getQuestionnaireId,
      questionnaireId,
    };
  },
};
</script>

<style>
</style>